<div class="mainform" style="text-align: center;">
  <br>
  <span *ngIf="message && messageCode.length==0">
    {{message}}
    <app-faq-link faq="authent"></app-faq-link>
  </span>

  <br><br>
  <app-hourglass maxwidth="300px" [message]="wait_message" faq="authent"></app-hourglass>

  <div *ngIf="wait_message.length==0">
    <div style="width: 100%;text-align: center;">
      <div *ngIf="messageCode.length==0">
        <br>
            <div class="login-button" style="display: inline-block;">
              <asl-google-signin-button type='standard' shape="rectangular"
                                        size='large' theme="filled_black"
                                        width="200"></asl-google-signin-button>
            </div>

<!--        <button class="app-button login-button"-->
<!--                id="cmdGoogle"-->
<!--                title="Authentification simplifiée en utilisant votre mail google"-->
<!--                mat-raised-button-->
<!--                (click)="socialSignIn('google')">-->
<!--          Google-->
<!--        </button>-->
<!--        <button class="app-button login-button"-->
<!--                mat-raised-button-->
<!--                id="cmdFacebook"-->
<!--                (click)="socialSignIn('facebook')">-->
<!--          Facebook-->
<!--        </button>-->

        <br>
        <button class="app-button login-button"
                title="Un code d'accès vous sera envoyé sur votre mail"
                mat-raised-button
                id="cmdEmail"
                (click)="email_login()">
          Email
        </button>
        <br>

        <button class="app-button login-button"
                mat-raised-button
                id="cmdAnonymous"
                title="Rester hors connexion"
                (click)="cancel()">
          Rester anonyme
        </button>
        <br>

      </div>

      <div *ngIf="messageCode.length>0">
        <br>
        {{messageCode}}<br>
        <br><br>

        <mat-form-field class="form-field-style" style="width: 150px !important;text-align: center;font-size: large;" >
          <mat-label>Code d'accès</mat-label>
          <input type="number"
                 matInput
                 autofocus
                 id="txtCode"
                 min="100000"
                 max="999999"
                 title="Saisissez votre code d'accès à 6 chiffres"
                 placeholder="Votre code"
                 [(ngModel)]="code"
                 (change)="updateCode($event)">
        </mat-form-field>

        <br>

        <app-tuto width="80%" style="margin-left:10%;display: inline-block;"
                  label="Le code à 6 chiffres vous a été envoyé sur votre adresse mail à votre première authentification. Si vous ne retrouvez pas le mail vous pouvez vous le faire renvoyer par {{appname}}">
        </app-tuto>

        <br>
        <button class="app-button"
                mat-raised-button
                *ngIf="code>10000"
                id="cmdValider"
                (click)="updateCode(code)">
          Valider
        </button>
        <br><br><br>
        <div *ngIf="code.length<4">
          <button class="app-button"
                  mat-raised-button
                  id="cmdResend"
                  (click)="resend_code()">
            Renvoyer le code
          </button>
          &nbsp;
          <button class="app-button"
                  mat-raised-button
                  id="cmdReset"
                  (click)="next()">
            Rester anonyme
          </button>
        </div>

        <br>

      </div>

    </div>
  </div>

</div>






