<div class="mainform" style="padding:5px">

    <div class="titleform">Administration</div>

    <mat-accordion [multi]="false" style="text-align: left;display:inline-block;">

        <mat-expansion-panel  [expanded]="true">
            <mat-expansion-panel-header>Général</mat-expansion-panel-header>
            <button mat-raised-button class="app-button" (click)="router.navigate(['search'])">Recherche</button>

            <button mat-raised-button class="app-button" (click)="openQuery()">Extraction</button>
            <button mat-raised-button class="app-button" (click)="update_imdb()">Update IMDB</button>
            <button mat-raised-button
                    title="Réindexation générale"
                    class="app-button" (click)="update_index()">Réindexer tout
            </button>
            <button mat-raised-button class="app-button" title="Mise a jour du modèle de la base de données" (click)="migrate_db()">Migrate Database</button>

            <button mat-raised-button class="app-button" (click)="openAPI()">APIs</button>
            <button mat-raised-button class="app-button" (click)="initdb()">Initialisation</button>
            <button mat-raised-button class="app-button" color="warn" (click)="raz('all')">Effacement TOTAL</button>

            <button mat-raised-button class="app-button" (click)="export_dict()">Dictionnaire<br>sous Excel</button>

            <button mat-raised-button class="app-button" (click)="apply_dict()"
                    title="Uniformiser la terminologie en réappliquant le dictionnaire aux expériences et aux oeuvres">
              Appliquer le<br>dictionnaire
            </button>

            <button mat-raised-button class="app-button" (click)="update_perms()"
                    title="Appliquer les permissions sur les utilisateurs suite à une mise a jour">
              Update des permissions
            </button>

            <hr>
            <button mat-raised-button class="app-button" (click)="send_update()"
                    color="secondary">Demande de mise a jour</button>
        </mat-expansion-panel>


        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>Backup</mat-expansion-panel-header>
          <p class="label">Backups disponibles</p>
          <table>
            <tr>
              <td>
                <app-input label="Charger la base" value_type="list"
                           width="300px" [options]="backup_files" value_field="value"
                           (valueChange)="sel_backup_file=$event" [value]="sel_backup_file">
                </app-input>
              </td>
              <td *ngIf="sel_backup_file">

                <button mat-raised-button class="app-button"
                        [title]="'Remplacer la base actuelle par le ficheir backup '+sel_backup_file.split('.backup')[0]"
                        (click)="load_backup()" >
                  <div class="bloc-bouton">
                    Charger
                  </div>
                </button>


                <button mat-raised-button class="app-button" color="warn"
                        title="Le chargement d'un backup nécéssite de vider préalablement la base actuelle"
                        (click)="raz('all')">
                  Vider la base
                </button>

                <button mat-raised-button class="app-button"
                        title="Le chargement d'un backup nécéssite de vider préalablement la base actuelle"
                        (click)="delete_backup()">
                  Supprimer
                </button>



              </td>
              <td>
                <app-upload-file  [zone]="true" extensions=".backup"
                                  [maxsize]="10000000000"
                                  height="100px" width="200px"
                                  (uploaded)="upload_backup($event)">
                  Ajouter un backup
                </app-upload-file>
              </td>
            </tr>
          </table>
            <br>

            <button mat-raised-button class="app-button"
                    (click)="save_backup()" >Faire un backup</button>

          <div style="margin-top: 15px" *ngIf="config.infos_server">
            Connexion à la base des oeuvres: {{config.infos_server.imdb_database_server}}<br><br>

            Liste des tables:<br>
            {{imdb_count}}
            <br><br>

            <a href="https://www.mongodb.com/docs/manual/release-notes/4.2-compatibility/#remove-support-for-the-copydb-and-clone-commands">
              Déplacer la base des oeuvres
            </a>

            <app-faq-link faq="autobackup_insall">Installer un backup automatique</app-faq-link>

          </div>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="show_server">
            <mat-expansion-panel-header>

                Serveur

            </mat-expansion-panel-header>

            <div style="width: 90%;background-color: #c4c4c4;padding: 10px;margin-left: 5%;min-height: 500px;" class="mat-elevation-z4">
                <ngx-json-viewer [expanded]="true" [json]="config.infos_server"></ngx-json-viewer>
            </div>

            <button mat-raised-button class="app-button" (click)="config.refresh_server()">Refresh</button>
            <button mat-raised-button class="app-button" (click)="openDjangoAdmin()">Admin Serveur</button>
          <button mat-raised-button class="app-button" (click)="obtain_token()">Token des API</button>

            <button mat-raised-button class="app-button" (click)="openServer(config.infos_server.domain.server)">
                Connexion serveur Django
            </button>

            <button mat-raised-button class="app-button" (click)="openServer(config.infos_server.database.default.HOST)">
                Connexion serveur Postgre
            </button>

            <button mat-raised-button class="app-button" (click)="openServer(config.infos_server.search.server.default.hosts)">
                Connexion a ElasticSearch
            </button>

        </mat-expansion-panel>



        <mat-expansion-panel [expanded]="true" *ngIf="config.config">
            <mat-expansion-panel-header>Profils</mat-expansion-panel-header>
            <ul *ngIf="config.isLogin()">
                <li><a [routerLink]="['/stats']" [queryParams]="{open:'student_by_lastmodification'}">Avancement des mises a jours</a><br><br></li>
                <li><a [routerLink]="['/stats']" [queryParams]="{open:'prostudent_by_depyear'}">Répartition de la formation pro</a><br><br></li>
            </ul>

            <br>
            <mat-slide-toggle [(ngModel)]="config.show_student">Inclure les étudiants</mat-slide-toggle><br><br>
            <mat-slide-toggle [(ngModel)]="config.config!.show_works">Inclure la carrière dans les profils partagés</mat-slide-toggle><br><br>

            <button mat-raised-button class="app-button" (click)="export_profils('S')">Export FI</button>
            <button mat-raised-button class="app-button" (click)="export_profils('P')">Export FPro</button>
            <button mat-raised-button
                    title="Réindexation des profils"
                    class="app-button" (click)="update_index('profils')">Réindexer les profils
            </button>
            <button mat-raised-button class="app-button" (click)="import_profils_from_mongo()">Importation depuis Mongo</button>
            <button mat-raised-button class="app-button" (click)="raz('profils')">RAZ profils</button>
            <button mat-raised-button
                    class="app-button" (click)="router.navigate(['import'])">Importation
            </button>
            <br>
            <button mat-raised-button class="app-button"
                    title="Traitement qualité sur les profils, les expériences et les Awards"
                    (click)="analyzer('profils,works')">
                Traitement Qualite des profils et expériences
            </button>

            <p>Gestion des contributions</p>
            <button mat-raised-button class="app-button" (click)="raz('works imdb')">RAZ IMDB</button>
            <button mat-raised-button class="app-button" (click)="raz('works unifrance')">RAZ Unifrance</button>
            <button mat-raised-button class="app-button" (click)="raz('works')">RAZ total</button>
        </mat-expansion-panel>


        <mat-expansion-panel  [expanded]="false">
            <mat-expansion-panel-header>
                Déploiement
            </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel  [expanded]="true" (afterExpand)="refresh()">
            <mat-expansion-panel-header>Utilisateurs</mat-expansion-panel-header>
            <table style="font-s0ize: small;line-height: 85%;text-align: center;width:100%;padding: 10px;" class="mat-elevation-z4">
                <tr>
                  <th></th>
                    <th>Login</th>
                    <th>Profils</th>
                    <th>Demande</th>
                </tr>
                <tr *ngFor="let u of users" style="width: 100%">
                  <td>
                    <mat-icon title="Supprimer le compte" class="mini-icon-button" (click)="del_user(u)">delete</mat-icon>
                    <mat-icon title="Refuser la demande de profil"
                              *ngIf="u.ask!=''"
                              style="font-size: 15px;cursor: pointer;"
                              (click)="cancel_ask(u)">cancel</mat-icon>
                    <mat-icon style="font-size: 15px;cursor: pointer;" (click)="send_email(u)">email</mat-icon>
                  </td>

                  <td style="width: 40%;">{{u.user.username}}</td>
                    <td style="width: 15%;">
                        <app-input [options]="profils" [value]="u.profil_name" value_type="list"
                                   value_field="id"
                                   (valueChange)="update_profil(u,$event)"></app-input>
                    </td>
                    <td style="width: 15%;">{{u.ask}}</td>

                </tr>
            </table>
            <br>
            <button mat-raised-button class="app-button" (click)="add_user()">Créer un utilisateur</button>
            <button mat-raised-button class="app-button" (click)="refresh()">Rafraichir</button>
        </mat-expansion-panel>


        <mat-expansion-panel  [expanded]="true">
            <mat-expansion-panel-header>
                NFT
            </mat-expansion-panel-header>
            <button mat-raised-button class="app-button" (click)="init_nft()">Init NFT</button>
        </mat-expansion-panel>


        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>Gestion des oeuvres</mat-expansion-panel-header>

            <div *ngIf="works_error.length>0" class="mat-elevation-z4" style="padding: 5px;">
              <div style="opacity: 0.8;margin:5px;">Expériences signalées</div>
              <table style="margin: 10px;width:100%;">
                <tr>
                  <th>Titre</th>
                  <th>Profil</th>
                  <th>Notification</th>
                </tr>
                <tr *ngFor="let w of works_error" style="padding: 5px;">
                  <td (click)="open_film(w)">{{w.pow__title}}</td>
                  <td (click)="open_profil(w)">{{w.profil__firstname+' '+w.profil__lastname}}</td>
                  <td>{{w.error_notification}}</td>
                  <td style="cursor: pointer">
                    <mat-icon title="Ouvrir la fiche et le film" (click)="open_work(w)">visibility</mat-icon>
                    <mat-icon title="Effacer la notification" (click)="clear_error_notification(w)">cancel</mat-icon>
                    <mat-icon title="Envoyer une message">mail</mat-icon>
                  </td>
                </tr>
              </table>
            </div>


            <div class="wrap-align" *ngIf="config.values && config.values.catalog">
                <mat-checkbox [(ngModel)]="config?.values?.catalog.imdb" >
                    IMDB
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;
                <mat-checkbox [(ngModel)]="config?.values?.catalog.femis" >
                    FEMIS
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;

                <mat-checkbox [(ngModel)]="config?.values?.catalog.unifrance" >
                    unifrance
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;

              <mat-checkbox [(ngModel)]="config?.values?.catalog.ledoc" >
                    leDoc
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;

                <mat-checkbox [(ngModel)]="config?.values?.catalog.senscritique">
                    senscritique
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;

                <mat-checkbox [(ngModel)]="config?.values?.catalog.lefilmfrancais">
                    lefilmfrancais
                </mat-checkbox>
                &nbsp;&nbsp;&nbsp;
                <mat-checkbox [(ngModel)]="config?.values?.catalog.cnc">
                    Le CNC
                </mat-checkbox>
            </div>

            <br><br>

            <div class="wrap-align">


                <button mat-raised-button
                        class="app-button" (click)="batch(1,100,false)">Mise a jour
                </button>

                <button mat-raised-button
                        class="app-button" (click)="ask_for_filter()">
                    MAJ filtrée
                </button>

                <button mat-raised-button
                        title="Mise a jour des profils non analysé depuis 30 jours et pour les oeuvres non récupérée depuis 90 jours"
                        class="app-button" (click)="fast_batch(30,90,2)">
                    MAJ rapide
                </button>

                <button mat-raised-button
                        title="Mise a jour des profils non analysé depuis 10 jours et pour les oeuvres non récupérée depuis 30 jours"
                        class="app-button" (click)="fast_batch(10,30,1)">
                    Ultra rapide
                </button>

                <button mat-raised-button
                        title="Mise a jour des profils non analysé depuis 10 jours et pour les oeuvres non récupérée depuis 30 jours"
                        class="app-button" (click)="fast_batch(10,30,1,true)">
                    Ultra rapide (deep)
                </button>

                <button mat-raised-button
                        title="Effectue un rechargement des oeuvres sous condition d'obsolescence de 31 jours "
                        class="app-button" (click)="batch(0,31,true)">
                    <div class="bloc-bouton">Mise a jour<br>forcée des profils</div>
                </button>

                <button mat-raised-button
                        title="Effectue un rechargement complet des oeuvres"
                        class="app-button" (click)="batch(0,0)">
                    <div class="bloc-bouton">Mise a jour<br>forcée totale</div>
                </button>

                <button mat-raised-button
                        title="Analyse film par film pour éventuel complément via d'autres sources"
                        class="app-button" (click)="batch_movies()">Complete Movies
                </button>

                <button mat-raised-button
                        title="Réindexation des films"
                        class="app-button" (click)="update_index('pows')">Réindexer les films
                </button>

                <button mat-raised-button
                        title="Traitement qualité des films"
                        class="app-button" (click)="analyzer('awards,films')">
                  Traitement Qualite<br>des oeuvres
                </button>

                <button mat-raised-button class="app-button" (click)="raz('pows')">Effacer les Films</button>
            </div>

        </mat-expansion-panel>

    </mat-accordion>

    <br>
    <app-hourglass [message]="message"></app-hourglass>
    <br>
</div>
