<div class="mainform" style="text-align: center">
  <app-hourglass [message]="message" style="color: white"></app-hourglass>
  <br>
  <div *ngIf="message.length==0" style="display: inline-block;width:85%;max-width: 1200px;">
    <div class="mat-headline-5" id="lblName">
      {{profil?.firstname+" "+profil?.lastname?.toUpperCase()}}
    </div>
    <br>
    <div style="text-align: center;object-fit: cover;" *ngIf="profil?.public_photo" >
      <img [src]="profil?.photo"
           style="max-width:120px;height:100%;border-radius: 10px;"
           class="mat-elevation-z4">
    </div>
    <br>
    <hr class="dark_separator">

    <table style="width:100%;margin:0;text-align:left;padding:0;;border-spacing: 10px;vertical-align: top;"
                 id="tableProperties"
                 class="mat-body-2 blur">
            <tr>
              <td title="Année de sortie de l'école" class="label mat-body-2" style="width: 120px;">Promotion<br></td>
              <td (click)="router.navigate(['search'],{queryParams:{query:profil.degree_year}})"
                  style="cursor: pointer;">{{profil?.degree_year}} {{profil?.degree_year_pro}}</td>
            </tr>

            <tr *ngIf="profil.department">
              <td [title]="'En savoir plus sur le département '+profil.department+' de la FEMIS'" class="label">Département</td>
              <td (click)="router.navigate(['search'],{queryParams:{query:profil.department}})" style="cursor: pointer;">
                {{profil?.department}}
              </td>
            </tr>

            <tr *ngIf="profil.department_pro">
              <td [title]="'En savoir plus sur le département '+profil.department_pro+' de la FEMIS'" class="label">Stage</td>
              <td (click)="router.navigate(['search'],{queryParams:{query:profil.department_pro}})" style="cursor: pointer;">
                {{profil?.department_pro}}
              </td>
            </tr>

            <tr *ngIf="profil.works?.length>0" id="rowExperiences">
              <td class="label">Domaine d'activité</td>
              <td>
                {{expe}}<br>
              </td>
            </tr>
            <tr *ngIf="profil?.links?.length>0 && config.config.public_version!='light'">
              <td class="label">Plus d'infos sur </td>
              <td style="text-align: left;">
                <div *ngFor="let link of profil?.links" style="display: inline-block;">
                  <a [href]="link.url" target="_blank" style="color: darkgray;">{{link.text}}</a>&nbsp;&nbsp;
                </div>
              </td>
            </tr>
          </table>


    <hr class="dark_separator">
    <br>


    <div style="margin: 5px;width:95%;line-height:120%;text-align: left;" *ngIf="message?.length==0 && profil.works.length+profil.awards.length>0">
      <div *ngIf="!config.config.show_works" style="text-align: center;">
        <div class="label" style="margin: 20px;">Voir le profil sur</div>
        <div style="width:100%" class="expansion-content">
          <div *ngFor="let p of platforms"
               [style.background-color]="p.color"
               style="display: inline-block;cursor: pointer;width: fit-content;text-align: center;" (click)="open_platform(p)">
            <img class="mat-elevation-z8" style="height:100px;margin:10px;" [src]="p.logo"><br>
            <span class="mat-body-2" style="color:grey;">
            {{p.title}}
          </span>
          </div>
        </div>

      </div>

      <mat-tab-group *ngIf="config.config.show_works">
        <mat-tab label="FILMOGRAPHIE" *ngIf="profil?.works?.length>0">
          <div class="label" style="margin-top: 20px" *ngIf="profil.works?.length>5">({{profil.works.length}} expériences)</div><br>
          <app-timeline
            style="margin-top: 20px;"
            (select)="open_film($event)"
            [data]="works_timeline"
            [field_style]="field_style"
            [field_class]="field_class"
            row_style="vertical-align: top;width:100%;">
          </app-timeline>
        </mat-tab>
        <mat-tab label="RECOMPENSES" *ngIf="awards_timeline?.length>0">
          <div class="label" *ngIf="profil.awards?.length>5" style="margin-top: 20px">({{profil.awards.length}} récompenses</div>
          <app-timeline class="column"
                        style="margin-top: 20px;"
                        (select)="open_film($event)"
                        [data]="awards_timeline"
                        [field_style]="field_style"
                        [field_class]="field_class"
                        row_style="vertical-align: top;width:100%;">
          </app-timeline>

        </mat-tab>
        <mat-tab label="NOMINATIONS" *ngIf="nominations_timeline?.length>0">
          <div class="label" *ngIf="profil.awards?.length>5" style="margin-top: 20px">({{profil.awards.length}} nominations</div>
          <app-timeline class="column"
                        style="margin-top: 20px;"
                        (select)="open_film($event)"
                        [data]="nominations_timeline"
                        [field_style]="field_style"
                        [field_class]="field_class"
                        row_style="vertical-align: top;width:100%;">
          </app-timeline>

        </mat-tab>
      </mat-tab-group>

    </div>

    <br>
    <div style="margin-top:50px;width:100%;text-align: center;filter: grayscale(0.3);" *ngIf="!config.config.show_works">
      <button mat-icon-button
              title="Partager ce profil"
              *ngIf="!config.isDesktop()"
              class="icon-button" (click)="share()">
        <mat-icon style="cursor: pointer;">shareic</mat-icon>
      </button>

      <div class="label mat-body-2">Partagez ce profil public sur vos réseaux</div><br>
      <!--      voir https://ngx-sharebuttons.netlify.app/ -->
      <share-buttons [theme]="'circles-light'"
                     style="text-align: center;filter: grayscale(1);opacity: 0.3;"
                     [include]="['facebook','twitter','linkedin','telegram','messenger','whatsapp','email','copy']"
                     [show]="8"
                     [url]="url"
                     [description]="profil.fullname+' sur l\'annuaire de la FEMIS'"
                     tags="FEMIS"
                     [title]="title">
      </share-buttons>
    </div>



</div>


<!--    <mgl-timeline [mobileWidthThreshold]="200"-->
<!--                  [toggle]="false"-->
<!--                  [alternate]="false"-->
<!--                  side="right"-->
<!--                  style="color:black"-->
<!--                  [focusOnOpen]="true">-->

<!--      <mgl-timeline-entry *ngFor="let wrk of items">-->

<!--        <mgl-timeline-entry-header>-->
<!--          {{wrk.pow.title}}<br>-->
<!--          <span style="font-size: x-small">{{wrk.pow.nature}}/{{wrk.pow.category}}</span>-->
<!--        </mgl-timeline-entry-header>-->
<!--        <mgl-timeline-entry-content style="font-size: small;line-height: 85%;">-->
<!--          {{wrk.job}}<br>-->
<!--          <small>-->
<!--            <a [href]="wrk.pow.link[0].url" target="_blank" class="app-link">-->
<!--              {{wrk.pow.link[0].text.replace('auto:','')}}-->
<!--            </a>-->
<!--          </small>-->
<!--        </mgl-timeline-entry-content>-->
<!--        <mgl-timeline-entry-dot [size]="40" style="color:red;"-->
<!--                                expandAnimationTiming="200ms ease"-->
<!--                                collapseAnimationTiming="100ms ease"-->
<!--        >-->
<!--        </mgl-timeline-entry-dot>-->
<!--        <mgl-timeline-entry-side *ngIf="wrk.show_year" style="font-size: 1.8em;text-align: right;color: white;">-->
<!--          <br>{{wrk.pow.year}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--        </mgl-timeline-entry-side>-->
<!--      </mgl-timeline-entry>-->
<!--    </mgl-timeline>-->



<!--  Travaux old version -->
<!--  <div *ngIf="profil?.works?.length>0" style="width: 100%;text-align: center;margin:0;">-->
<!--    <br><br>Expériences<br><br>-->
<!--    <div class="wrap-list" style="width: 100%;">-->
<!--      <app-work *ngFor="let wrk of works"-->
<!--                [work]="wrk"-->
<!--                style="margin:5px;"-->
<!--                maxwidth="450px"-->
<!--                width="350px"-->
<!--                minheight="100px"-->
<!--                height="auto"-->
<!--                backgroundColor="#1c1a1a">-->
<!--        <br>-->

<!--          <img  *ngFor="let a of wrk.pow.awards"-->
<!--                src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/twitter/282/trophy_1f3c6.png"-->
<!--                style="width: 35px;display: inline-block;margin:3px;cursor: pointer"-->
<!--                (click)="open_price(a,wrk.pow.title)"-->
<!--                [title]="a.title+': '+a.description+'('+a.year+')'"-->
<!--               >-->

<!--      </app-work>-->
<!--    </div>-->
<!--  </div>-->


</div>
