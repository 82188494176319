<app-tuto   title="<br><br><br><br><br><strong>{{environment.appname}}</strong>"
            color="white"
            [image]="environment.splash.logo"
            subtitle="<strong>{{environment.splash.intro}}</strong>"
            [background]="environment.splash.image">
</app-tuto>


<div class="bodybg">



  <mat-toolbar class="toolbar-app" (mousedown)="closeMenu()" *ngIf="config.toolbar">
    <div style="text-align: right;" *ngIf="sidemenu_mode=='side'">
      <mat-icon style="cursor: pointer;margin: 10px;color:white;" (click)="drawer.toggle()">
        menu_open
      </mat-icon>
    </div>
    <mat-icon
            id="cmdMenu" *ngIf="sidemenu_mode=='over'"
            (click)="drawer.toggle()"
            style="cursor: pointer;margin: 10px;color:white;">
      menu
    </mat-icon>

    <img [src]="environment.icon_appli" style="width: 25px;margin: 10px;">

    <span class="appli-title">
    {{environment.appname}}
      <div style="font-size: x-small;display: inline;margin: 5px;" routerLink="about" *ngIf="!simple_screen">&nbsp;v{{appVersion}}</div>
  </span>

    <span class="fill-remaining-space"></span>

    <a *ngIf="!simple_screen && environment!.support!.website"
        [href]="environment!.support!.website" target="_blank" >
      <img [src]="environment.logo_transparent" style="width: 80px;margin-right: 15px;">
    </a>

    <mat-icon style="font-size: x-large;cursor: pointer;"
              title="Revenir à l'écran précédent"
              id="cmdBack"
              (click)="_location.back()">navigate_before</mat-icon>
  </mat-toolbar>


  <mat-drawer-container class="container">
    <mat-drawer #drawer class="sidenav" [mode]="sidemenu_mode" *ngIf="config.sideMenu">
      <app-loginbar (click)="closeMenu()" (logout)="logout()">
      </app-loginbar>
    </mat-drawer>

    <mat-drawer-content style="overflow: scroll">
      <div class="sidenav-content" (mousedown)="closeMenu()">
        <router-outlet style="width:100%;" *ngIf="message.length==0" ></router-outlet>
      </div>
    </mat-drawer-content>

  </mat-drawer-container>
</div>
