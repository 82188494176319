<div class="mainform">
  <br><br><br>
  <h4>
    {{environment.appname}}<br>
    est en cours de maintenance<br>
  </h4>

  <br>
  <br>
  <img src="./assets/under_construction.gif"/>
  <br>
  <br><br>
  <br><br>
  <br>
  Veuillez vous reconnecter ultérieurement
</div>
