<app-tuto title="<br><br><br><br><br><strong>Les statistiques</strong>"
          color="white"
          image=""
          subtitle="Analyser les parcours et personnaliser les nombreux rapports disponibles sur l'activité de l'école"
          background="./assets/img/charts.jpeg">
</app-tuto>


<div class="mainform" style="height: 100%;">
  <app-hourglass [message]="message"></app-hourglass>
  <div *ngIf="sel_report=='building'">
    <br>
    Rapport en cours de construction
  </div>

  <mat-tab-group style="height: 87vh;text-align: center;width:100%;" id="tabStats" #tabGroup mat-align-tabs="start">
    <mat-tab name="tabs" label="Rapports pré-définis">
      <div class="expansion-content" style="width: 100%;margin-top: 15px;">
        <app-input label="Filtre" [value]="filter_report"
                   width="100px" style="margin-right: 10px;"
                   (valueChange)="filter_report=$event"></app-input>

        <mat-form-field appearance="fill" style="max-width: 500px;flex-grow:2;">
          <mat-label>Sélectionner un rapport</mat-label>
          <mat-select [(value)]="sel_report" (selectionChange)="change_report($event.value.id)" id="lstReports">
            <mat-option *ngFor="let report of instant_reports | filter:['title',filter_report]" [value]="report">
              {{report.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>



          <div *ngFor="let filter of filters" style="display:inline-flex;">
            <app-input [options]="filter.values" [label]="filter.label"
                       value_type="list" [value]="filter.value"
                       (valueChange)="update_filter_value(filter,$event)"></app-input>
          </div>
          <button mat-raised-button style="margin: 5px;margin-top: -15px;"
                  *ngIf="filters!.length>0"
                  (click)="clear_filters()">Pas de filtres</button>





<!--        <div *ngIf="filter_values?.length>0" style="display: inline-block;">-->
<!--          <mat-form-field class="app-field" style="font-size: medium;max-width: 150px;" id="lstFilter" >-->
<!--            <mat-label>{{filter_name}}</mat-label>-->
<!--            <mat-select [(ngModel)]="sel_filter" (selectionChange)="refresh_stats()">-->
<!--              <mat-option *ngFor="let filter_value of filter_values" [value]="filter_value">{{filter_value}}</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          <mat-icon style="cursor: pointer;font-size: small;"-->
<!--                    *ngIf="sel_filter?.length>0"-->
<!--                    (click)="cancel_filter()">cancel</mat-icon>-->
<!--        </div>-->

        <mat-icon [title]="sel_report.description" style="cursor: help;margin-left:20px;"
                  *ngIf="sel_report.description?.length>0">help</mat-icon>

      </div>



      <div style="width: 100%;text-align: center;position: relative;">

<!--        Zone de patiente-->
        <div *ngIf="sel_report?.html_values?.length==0"
             style="width:80%;display: inline-block;text-align: center;margin-top: 10%;">
          <img src="/assets/img/gears.gif" width="150">
          <br>
          Construction en cours<br>
          {{sel_report.description}}
        </div>

        <iframe *ngIf="sel_report?.html_code"
                class="mat-elevation-z4 primary-color"
                style="padding: 0;width: 95%;height:69vh;border:None;display: inline-block;margin:0;overflow: hidden;background:none;"
                [title]="sel_report.description"
                [srcdoc]="sel_report.html_code | safe:'html'">
        </iframe>
      </div>

      <br>
<!--      -->
      <div *ngIf="sel_report?.html_values?.length>0" style="width:100%;min-width:350px;height: auto;margin:10px;overflow: scroll;">
        <mat-expansion-panel  style="display: inline-block;height: fit-content;width:100%;" [expanded]="false" >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Données détaillées
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div style="width: 90vw;height:60vh;border:None;overflow: scroll;"
               [innerHTML]="sel_report.html_values | safe:'html'">
          </div>
        </mat-expansion-panel>
      </div>

    </mat-tab>

    <mat-tab name="tabs" label="Exports" *ngIf="config.hasPerm('export_stats') && isLocal()">

      <br>
      <mat-form-field appearance="outline" style="min-width: 300px;width:5%;">
        <mat-label>Liste des raports</mat-label>
        <mat-select multiple [(ngModel)]="sel_instant_report_to_copy">
          <mat-option *ngFor="let report of instant_reports" [value]="report.id">
            {{report.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button
              class="app-button"
              id="cmdCopyReport"
              *ngIf="sel_instant_report_to_copy?.length>0"
              (click)="copyInstantReports()">
        Recevoir par mail
      </button>

      <br>
      <br>

      <mat-expansion-panel style="display: inline-block;width: fit-content;max-width: 800px;text-align: left;" [expanded]="true">
        <mat-expansion-panel-header>
          Travailler sur l'ensemble des données
        </mat-expansion-panel-header>
        <div>

          <mat-form-field appearance="fill" style="width: 200px;">
            <mat-label>Sélectionner une categorie</mat-label>
            <mat-select [(value)]="sel_table" id="lstTable">
              <mat-option value="work">Travaux</mat-option>
              <mat-option value="award">Récompenses</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;

          <mat-checkbox [(ngModel)]="bLimitData" style="margin: 10px;">
            <div class="bloc-bouton" style="text-align: left;"
                 title="Voir toutes les données peut significativement alonger le temps de traitement">Voir toutes<br>les données</div>
          </mat-checkbox>
        </div>

        <table style="margin: 0px;padding: 20px;width: fit-content;display: inline-block;" *ngIf="!showGraphQL">
          <tr>
            <td>Données brutes</td>
            <td style="text-align: left;">
              <button mat-raised-button class="app-button" style="width: 140px;"
                      (click)="downloadReport('csv')">
                <mat-icon>download</mat-icon>&nbsp;Excel (CSV)
              </button>
              &nbsp;
              <button mat-raised-button class="app-button" style="width: 140px;"
                      (click)="downloadReport('xml')">
                <mat-icon>download</mat-icon>&nbsp;XML
              </button>

              <button mat-raised-button class="app-button" style="width: 140px;"
                      (click)="downloadReport('json')">
                <mat-icon>download</mat-icon>&nbsp;JSON
              </button>
            </td>
          </tr>

          <tr>
            <td>Modèle d'analyse</td>
            <td style="text-align: left;">
              <button mat-raised-button class="app-button" style="width: 140px;"
                      (click)="downloadReport('excel')">
                <mat-icon>download</mat-icon>
                Excel
              </button>
              &nbsp;
              <button mat-raised-button class="app-button" style="width: 140px;"
                      (click)="downloadReport('powerbi')">
                <mat-icon>download</mat-icon>
                PowerBI
              </button>
            </td>
          </tr>
        </table>


      </mat-expansion-panel>








      <!--      <mat-form-field class="app-field" style="font-size: medium;max-width: 300px;" id="lstRapports">-->
      <!--        <mat-label>Rapports statistiques disponibles</mat-label>-->
      <!--        <mat-select [(ngModel)]="sel_report">-->
      <!--          <mat-option *ngFor="let report of reports" [value]="report.url">{{report.title}}</mat-option>-->
      <!--        </mat-select>-->
      <!--        <mat-hint>Sélectionner le rapport que vous souhaitez consulter</mat-hint>-->
      <!--      </mat-form-field>-->

      <!--      <button mat-raised-button-->
      <!--              class="app-button"-->
      <!--              id="cmdOpenStat" color="primary"-->
      <!--              *ngIf="sel_report?.length>0 && sel_report!='building'"-->
      <!--              (click)="openStats()">-->
      <!--        Ouvrir-->
      <!--      </button>-->









    </mat-tab>

    <mat-tab name="tabs" label="Documentation">
      <br>
      <p>La liste des données disponibles via {{environment.appname}} pour construire les reportings</p>

      <br>

      <table class="mat-elevation-z4"  *ngIf="rows.length>0" style="padding: 15px;">
        <tr>
          <th style="text-align: left;">Catégorie</th>
          <th style="text-align: left;">Nom du champs</th>
          <th>Signification</th>
        </tr>
        <tr *ngFor="let row of rows" style="width: 90% !important;">
          <td style="width: 20%;text-align: left;">{{row.table}}</td>
          <td style="width: 20%;max-width: 60px;">{{row.field}}</td>
          <td>{{row.description}}</td>

        </tr>
      </table>


    </mat-tab>

  </mat-tab-group>

  <br>
  <br>


  <div *ngIf="showGraphQL">
    <app-tuto label="Grâce aux langages GraphQL vous pouvez interroger la base des anciens éleves"></app-tuto>
    <textarea
            [rows]="10"
            style="margin:2%;width: 95%;background-color: lightgray;"
            placeholder="Votre requête ici" [(ngModel)]="query">
    </textarea>
  </div>



</div>

<div class="bottom-bar">
  <hr>

  <!--    <button mat-raised-button-->
  <!--            *ngIf="config.query_cache?.length>0"-->
  <!--            title="Exporter le résultat de votre dernière recherche"-->
  <!--            class="app-button"-->
  <!--            (click)="export_stats()">-->
  <!--      Export-->
  <!--    </button>-->

  <div *ngIf="tabGroup?.selectedIndex==0" style="display: inline-block">
    <button mat-raised-button
            class="app-button"
            id="cmdOpen"
            *ngIf="sel_report?.url?.length>0"
            (click)="open_stats()">
      <div class="bloc-bouton">Agrandir le<br>graphique</div>
    </button>

    <button mat-raised-button
            class="app-button"
            id="cmdExport"
            *ngIf="sel_report?.url?.length>0"
            (click)="export_stats()">
      <div class="bloc-bouton">Exporter<br>sous Excel</div>
    </button>

    <button mat-raised-button
            class="app-button"
            id="cmdShare"
            *ngIf="sel_report?.url?.length>0"
            (click)="share_stats()">
      <div class="bloc-bouton">Partager<br>le rapport</div>
    </button>

    <button mat-raised-button
            class="app-button"
            id="cmdRefresh"
            (click)="share_stats()">
      Refresh
    </button>

  </div>

  <div *ngIf="tabGroup?.selectedIndex==2" style="display: inline-block">
  <button mat-raised-button
          title="Exporter ce tableau au format JSON"
          class="app-button"
          (click)="export_doc('json',true)">
    Exporter en JSON
  </button>

  <button mat-raised-button
          title="Exporter ce tableau au format JSON"
          class="app-button"
          (click)="export_doc('csv',false)">
    Exporter pour Excel
  </button>
  </div>

  <button mat-raised-button
          class="app-button"
          id="cmdOpenSocialGraph"
          (click)="openSocialGraph()">
    <div class="bloc-bouton">Graphe<br>social</div>
  </button>

  <!--  <button mat-raised-button-->
  <!--          class="app-button"-->
  <!--          (click)="showGraphQL=true">-->
  <!--    GraphQL-->
  <!--  </button>-->

</div>
