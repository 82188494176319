import { Component } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-under-construction',
  standalone: true,
  imports: [],
  templateUrl: './under-construction.component.html',
  styleUrl: './under-construction.component.sass'
})
export class UnderConstructionComponent {

  protected readonly environment = environment;
}
