<div class="mainform" style="height: 100vh;padding: 0;margin: 0;">
  <div *ngIf="environment.vitrine_title" class="mat-headline-5" style="margin:2%">
    {{environment.vitrine_title}}
  </div>

  <owl-carousel-o [options]="customOptions" class="carousel" *ngIf="_type==1">
    <ng-template *ngFor="let profil of profils" carouselSlide>
      <div [style.background-image]="'url('+profil.photo+')'" class="large-image vignette" (click)="show_public(profil)">
        <div class="image-title">
          <div class="firstname">{{profil.firstname}}</div>
          <br><br><br>
          <div class="lastname">{{profil.lastname}}</div>
        </div>

        <div class="image-claim">
          {{profil.claim}}
        </div>

        <div class="toolbar" *ngIf="config.hasPerm('vitrine')">
          <mat-icon class="toolbar-button" title="ne plus mettre en avant le profil" (click)="remove_profil(profil)">cancel</mat-icon>
          <mat-icon class="toolbar-button" title="Modifier l'accroche" (click)="edit_claim_profil(profil)">edit</mat-icon>
          <mat-icon class="toolbar-button" title="Modifier le lien vers l'article" (click)="edit_claim_link(profil)">link</mat-icon>
          <mat-icon class="toolbar-button" *ngIf="config.hasPerm('edit_profil')"
                    title="Editer le profil" (click)="edit_profil(profil)">settings</mat-icon>
        </div>

        <div class="image-footer">
          Promotion {{profil.degree_year}}
        </div>

      </div>
    </ng-template>
  </owl-carousel-o>


  <div class="expansion-content" *ngIf="_type==2">
    <div *ngFor="let profil of profils" [style.background-image]="'url('+profil.photo+')'"
         class="large-image-contain" (click)="show_public(profil)">
      <div class="image-title">
        <div class="firstname">{{profil.firstname}}</div>
        <br><br>
        <div class="lastname">{{profil.lastname}}</div>
      </div>

      <div class="image-claim-top">
        {{profil.claim}}
      </div>

      <div class="toolbar" *ngIf="config.hasPerm('vitrine')">
        <mat-icon class="toolbar-button" title="ne plus mettre en avant le profil" (click)="remove_profil(profil)">cancel</mat-icon>
        <mat-icon class="toolbar-button" title="Modifier l'accroche" (click)="edit_claim_profil(profil)">edit</mat-icon>
        <mat-icon class="toolbar-button" title="Modifier le lien vers l'article" (click)="edit_claim_link(profil)">link</mat-icon>
        <mat-icon class="toolbar-button" *ngIf="config.hasPerm('edit_profil')"
                  title="Editer le profil" (click)="edit_profil(profil)">settings</mat-icon>
      </div>

      <div class="image-footer">
        Promotion {{profil.degree_year}}
      </div>
    </div>
  </div>
</div>



