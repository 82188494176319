<div class="mainform" *ngIf="config.values">

  <br>
  <div class="titleform">{{config.values.appname}}</div>
  <div style="width: 100%;text-align: center;font-size: small;margin-top:5px;">
    <div *ngIf="config.values.logo?.length>0">
      <img
              [src]="config.values.logo"
              [ngStyle]="{'width':150,'margin':'15px'}">
      <br>
    </div>
    <small>Version:</small>
    <br>{{appVersion}}<br><br><br>
    <table style="display: inline-block;">
      <tr>
        <td style="width: 50%;text-align: right;" class="label">Réalisation</td>
        <td>&nbsp;</td>
        <td style="width: 50%;text-align: left;">
          <a style="color:lightgray;font-variant: normal;font-weight: normal;" [href]="config.values.editor?.link_author" target="_blank">
            {{config.values.editor?.author}}
          </a>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;text-align: right;" class="label">Edition</td>
        <td>&nbsp;</td>
        <td style="width: 50%;text-align: left;">
          <a style="color:lightgray;font-variant: normal;font-style: normal;font-weight: normal;" [href]="config.values.editor?.website" target="_blank">
            <strong>{{config.values.editor?.name}}</strong>
          </a>
        </td>
      </tr>
      <tr>
        <td style="width: 50%;text-align: right;" class="label">Conditions générales</td>
        <td>&nbsp;</td>
        <td style="width: 50%;text-align: left;">
          <a style="color:lightgray;font-variant: normal;font-style: normal;font-weight: normal;" title="Conditions d'utilisation"  routerLink="cgu" id="cmdCGU">
            CGU
          </a>
        </td>
      </tr>
      <tr><td><br></td></tr>
      <tr>
        <td style="text-align: right;opacity: 0.4;">Films / Expériences référencées</td>
        <td></td>
        <td style="text-align: left;">{{config.infos_server?.content?.films}} / {{config.infos_server?.content?.works}}</td>
      </tr>

      <tr>
        <td style="text-align: right;opacity: 0.4;">Profils enregistrés</td>
        <td></td>
        <td style="text-align: left;">{{config.infos_server?.content?.profils}}</td>
      </tr>
      <tr></tr>
      <tr>
        <td style="text-align: right;opacity: 0.4;">Récompenses</td>
        <td></td>
        <td style="text-align: left;">{{config.infos_server?.content?.awards}} récompenses<br>sur
          {{config.infos_server?.content?.festivals}} festivals</td>
      </tr>

    </table>
    <br><br>
    <div style="width: 100%;text-align: center;font-size: medium;">

      <button class="icon-button" mat-icon-button *ngIf="environment.support.email!=''"
              (click)="openMail(environment.support.email)">
        <mat-icon>mail</mat-icon>
      </button>

      &nbsp;
      <button class="icon-button" mat-icon-button *ngIf="environment.support.forum!=''"
              (click)="openFrame(environment.support.forum)">
        <mat-icon class="big-icon">persons</mat-icon>
      </button>
      &nbsp;
      <button class="icon-button"
              mat-icon-button *ngIf="environment.support.faq!=''"
              (click)="faq()">
        <mat-icon >help</mat-icon>
      </button>
      &nbsp;
      <button class="icon-button" mat-icon-button
              *ngIf="environment.support.website!=''"
              (click)="openFrame(environment.support.website)"
      >
        <mat-icon >home</mat-icon>
      </button>
    </div>
  </div>

  <br>
  <button mat-raised-button (click)="router.navigate(['issues'])">
    <div class="bloc-bouton">Signaler<br>un problème</div>
  </button>

</div>
