import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {$$, getParams, open_report, remove_ponctuation, showError, showMessage, translateQuery, uniq} from "../tools";
import {ConfigService} from "../config.service";
import {NgNavigatorShareService} from "ng-navigator-share";
import {ClipboardService} from "ngx-clipboard";
import {environment} from "../../environments/environment";
import {MatAccordion} from "@angular/material/expansion";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";



@Component({
  selector: 'app-pows',
  templateUrl: './pows.component.html',
  styleUrls: ['./pows.component.sass']
})
export class PowsComponent implements AfterViewInit {
  pows: any[]=[];
  query: any={value:""};
  limit=300;
  @ViewChild('powAccordion') powAccordion: MatAccordion;
  filter_id: number=0;

  constructor(public api:ApiService,
              public ngNavigatorShareService:NgNavigatorShareService,
              public _clipboardService:ClipboardService,
              public router:Router,
              public toast:MatSnackBar,
              public _location:Location,
              public routes:ActivatedRoute,
              public config:ConfigService) {
    this.config.user_update.subscribe((new_user)=>{this.refresh();})
  }

  ngAfterViewInit(): void {
      getParams(this.routes).then((params:any)=>{
        this.query.value=params.hasOwnProperty("filter") ? remove_ponctuation(params.filter) : remove_ponctuation(params.query);
        this.filter_id=params.hasOwnProperty("id") ? Number(this.routes.snapshot.queryParamMap.get("id")) : 0;
        if(this.query.value!="")this.refresh();
      })

  }

  open_search(work: any) {
    this.router.navigate(["search"],{queryParams:{filter:work.name}});
  }


  clearQuery() {
    this.query.value='';
    this.refresh(this.limit);
  }


  message: string ="";


  handle:any;
  onQuery($event: KeyboardEvent) {
    clearTimeout(this.handle)
    this.handle=setTimeout(()=>{this.refresh(this.limit)},400)
  }

  switch_andor() {
    //Basculer la recherche sur l'obligation d'avoir tous les mots

  }

  refresh(limit=300) {
    if(!this.query.value || this.query.value=="")return;
    if(this.query.value.length>3)this._location.replaceState("pows/?query="+this.query.value);
    let param=translateQuery(this.query.value,false);
    param=param+"&limit="+limit+"&default_operator=and";
    this.api._get("powsdoc",param).subscribe((r:any)=>{
      this.message="";
      this.pows=[];
      for(let i of r.results){
        if(i.hasOwnProperty("links")){
          try{i.links=uniq(i.links);}catch (e){}
        }
        if(!this.filter_id || this.filter_id==i.id){
          let origin=i.links.length>0 ? i.links[0].text.split(":")[1] : "*";
          if(origin){
            origin=origin.replace("-","");
            i.class=(this.pows.length==0) ? "item-first" : ""
            i.best=(this.pows.length==0)
            if(origin=="*" || this.config.hasPerm("r_"+origin.toLowerCase()))
              this.pows.push(i);
          } else {
            $$(i.links[0].url+" est en anomalie");
          }
        }
      }
      if(r.results.length==1){
        setTimeout(()=>{
          if(this.pows.length>0)this.pows[0].expanded=true;
        },1000);
      }
      if(r.results.length==0 && this.query.value && !this.query.value.endsWith("*")){
        this.handle=setTimeout(()=>{
          if(this.pows.length==0){
            this.query.value=this.query.value+"*"
            this.refresh()
          }
        },3000)
      }
    },(err)=>{
      showError(this,err);
    });
  }


  add_experience(pow: any) {
    this.router.navigate(["edit"],{queryParams:{id:this.config.user.profil,add:pow.id,title:pow.title}})
  }

  share(pow: any) {
    let public_url=environment.domain_appli+"/pows?query="+pow.title;
    showMessage(this,"Lien du profil disponible dans le presse-papier");
    this.ngNavigatorShareService.share({
      title: pow.title,
      text: "Retrouver les films de la femis",
      url: public_url
    })
      .then( (response) => {console.log(response);},()=>{
        this._clipboardService.copyFromContent(public_url);
      })
      .catch( (error) => {
        this._clipboardService.copyFromContent(public_url);
      });

  }

  get_pow(pow: any) {
    this.api._get("extrapows/"+pow.id,"").subscribe((r:any)=>{
      let rc=[];
      if(r){
        pow.title=r.title;
        pow.visual=r.visual;
        pow.award=Object.values(r.award).sort((a:any,b:any) => (a.year<b.year ? 1 : -1));
        for(let item of r.works){
          if(item.public){
            rc.push({
              job:item.job,
              name:item.profil.firstname+" "+item.profil.lastname.toUpperCase()
            })
          }
        }
        pow.expanded=true;
        pow.works=rc;
      }
    });
  }

  deletePow(pow: any) {
    this.api._delete("/pows/"+pow.id).subscribe(()=>{
      showMessage(this,"film supprimé");
      this.refresh(this.limit);
    })
  }

  show_all() {
    if(this.limit==50)
      this.limit=500;
    else
      this.limit=100;

    this.refresh(this.limit);
  }

  openGoogle(pow: any) {
    let url=pow.title.replace(" ","+")+" "+pow.year;
    open("https://www.google.com/search?q="+url,"_blank");
  }

  analyse(pow: any) {
    this.api._get("analyse_pow","id="+pow.id+"&search_by=link").subscribe((r:any)=>{
      showMessage(this,"Analyse terminée");
    });
  }

  award_filter(awards: any[], winner: boolean) {
    return awards.filter(i=>i.winner==winner)
  }

  open_chart() {
    open_report("award_by_department",this.api)
  }

  quality(pow: any) {
    showMessage(this,"Traitement qualité en cours sur "+pow.title)
    this.api._get("quality_analyzer","ope=films,award&filter="+pow.id).subscribe(()=>{
      showMessage(this,"Traitement terminé")
      this.refresh()
    })
  }


}
