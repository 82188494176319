import {Component, Inject, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {tProfil} from "../types";
import {wait_message} from "../hourglass/hourglass.component";
import {NgFor, NgIf} from "@angular/common";
import {CarouselModule, OwlOptions} from "ngx-owl-carousel-o";
import {MatIcon} from "@angular/material/icon";
import {ConfigService} from "../config.service";
import {_prompt} from "../prompt/prompt.component";
import {MatDialog} from "@angular/material/dialog";
import {getParams, setParams, showMessage} from "../tools";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-vitrine',
  standalone: true,
  imports: [
    NgIf, CarouselModule, NgFor, MatIcon
  ],

  templateUrl: './vitrine.component.html',
  styleUrl: './vitrine.component.sass'
})
export class VitrineComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoWidth: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay: true,
    margin: 50,
    autoHeight: true,
    autoplaySpeed: 2000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      800: {
        items: 3
      },
      1200: {
        items: 4
      }
    },
    nav: true
  }

  routes=inject(ActivatedRoute)
  api=inject(ApiService)
  profils: tProfil[]=[]
  config = inject(ConfigService)
  dialog=inject(MatDialog)
  toast=inject(MatSnackBar)
  router=inject(Router)
  _type=1

  load_profils(){
    wait_message(this,"Chargement des profils")
    //test https://femis.f80.fr:8000/api/profilsdoc?showcase__range=1__10
    this.api._get("profilsdoc", "showcase__range=1__10").subscribe((resp: any) => {
      this.profils=[]
      for(let p of resp.results){
        if(p.photo && !p.photo.endsWith("anonymous.png")){
          this.profils.push(p)
        }
      }
      if(this.profils.length==0)this.router.navigate(["search"])
    })
  }

  async ngOnInit() {
    this.load_profils()
    this.config.sideMenu=false
    this.config.toolbar=false
    let params:any=await getParams(this.routes)
    this._type=Number(params.type || "1");
  }


  remove_profil(profil: tProfil) {
    this.api._put("profils/"+profil.id,"",{showcase:0}).subscribe(()=>{
      showMessage(this,"Le profil n'est pas mis en avant")
      this.load_profils()

    })
  }

  async edit_claim_profil(profil: tProfil) {
    let r=await _prompt(this,"Texte d'introduction",profil.claim,"Texte afficher sous la photo","text","Valider","Annuler",false)
    if(r){
      showMessage(this,"Mise a jour du profil")
      this.api._put("profils/"+profil.id,"",{claim:r}).subscribe(()=>{
        this.load_profils()
      })
    }
  }

  protected readonly environment = environment;

  edit_profil(profil: tProfil) {
    this.router.navigate(["edit"],{queryParams:{p:setParams({id:profil.id},"","")}})
  }

  show_public(profil:tProfil) {
    if(!this.config.hasPerm("vitrine")){
      if(profil.claim_link && profil.claim_link.length>0){
        this.router.navigate([profil.claim_link])
      }else{
        this.router.navigate(["public"],{queryParams:{p:setParams({id:profil.id,name:profil.firstname+" "+profil.lastname},"","")}})
      }

    }
  }

  async edit_claim_link(profil: tProfil) {
    let r=await _prompt(this,"Lien web vers l'article",profil.claim_link,"","text","Valider","Annuler",false)
    if(r){
      showMessage(this,"Mise a jour du profil")
      this.api._put("profils/"+profil.id,"",{claim_link:r}).subscribe(()=>{
        this.load_profils()
      })
    }
  }
}
