import { Component, OnInit } from '@angular/core';
import {_prompt, PromptComponent} from "../prompt/prompt.component";
import {checkConfig, checkLogin, detailPerm, setParams, showError, showMessage} from "../tools";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigService} from "../config.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit {
  show_perm="";

  constructor(
    public dialog:MatDialog,
    public api:ApiService,
    public router:Router,
    public toast:MatSnackBar,
    public config:ConfigService
  ) { }

  async ngOnInit() {
    if(await checkLogin(this)){
      this.show_perm=detailPerm(this.config.user.perm,this.config.perms,"html");
    } else {
      this.router.navigate(["search"])
    }
  }

  open_perms(){
    this.router.navigate(['profils']);
  }

  async raz() {
    try{
      let r=_prompt(this,"Etes vous certain de vouloir supprimer votre compte ?")
      if(r){
        this.api._delete("users/"+this.config.user.user.id+"/","").subscribe(()=>{
          showMessage(this,"Votre compte est effacé");
          window.location.reload();
        })
      }
    }catch(e){
      showMessage(this,"Votre compte est toujours actif")
    }

  }


    open_profils() {
        this.router.navigate(["search"])
    }

    open_movies() {
        this.router.navigate(["pows"])
    }

  switch_expert_mode() {
    if(this.config && this.config.user){
      this.config.user.level=!this.config.user.level ||this.config.user.level==0 ? 1 : 0
    }
  }

  edit_profil() {
    this.router.navigate(["edit"],{queryParams:{p:setParams({id:this.config.user.profil},"","")}})
  }

  async clear_profil() {
    try{
      let r=await _prompt(this,"Etes vous sûr de vouloir retirer votre profil de l'annuaire","","")
      if(r){
        this.api._patch("/profils/"+this.config.user.profil,"",{online:false}).subscribe(()=>{
          showMessage(this,"Votre profil n'est plus dans l'annuaire, contacter "+environment.support.email+" pour demander sa réintégration")
        })
      }
    }catch (e) {
      showMessage(this,"Votre profil est toujours présent dans l'annuaire")
    }
  }

  protected readonly environment = environment;

  update_compte(new_value: any,option:any) {
    for(let i=0;i<this.config.user.optin.length;i++){
      if(option.id==this.config.user.optin[i])this.config.user.optin[i].value=new_value
    }
    this.api._patch("extrausers/"+this.config.user.user.id,"",{optin:this.config.user.optin}).subscribe({
      next:()=>{showMessage(this,"Informations enregistrées")},
      error:(err:any)=>{
        showError(this,err)
      }
    })
  }
}
